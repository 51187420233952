export default function Footer ()  {
    return (
      <footer class="relative bg-[#232323] text-white px-4 sm:px-8 lg:px-16 xl:px-40 2xl:px-64 pt-16 pb-16 text-center sm:text-left text-xs">
      <div class="flex flex-col sm:flex-row sm:flex-wrap">
      <div class="mt-12 sm:w-1/2 lg:w-2/5 lg:mt-0 pr-20">
        <img class="max-w-[110px] mb-5" src="/logo.png" alt="Footer Logo" />
        <div>
          {/* <p class="text-gray-300 my-4 leading-5">Everything we do at Warmhearted helps to make the world a better place.</p> */}
          <p class="text-gray-300 my-4 leading-5">© {`${new Date().getFullYear()}`} Tranxactor New Zealand Limited. All rights reserved. </p>
          <p class="text-gray-400 mb-1">Ph +64(9) 366 1890</p>
          <p class="text-gray-400">support@tranxactor.com</p>
        </div>
      </div>

        <div class="sm:w-1/2 lg:w-1/5">
          <h6 class="text-lg font-bold uppercase">About Us</h6>
          <ul class="mt-4">
            {/* <li class="text-gray-500 hover:text-gray-300 mb-3"><a href="#">About us</a></li> */}
            <li class="text-gray-500 hover:text-gray-300 mb-3"><a href="/partneringwithwarmhearted">Partnering with Warmhearted</a></li>
            <li class="text-gray-500 hover:text-gray-300 mb-3"><a href="https://transactortech.co.nz/Warmhearted/">Partner Login</a></li>
          </ul>
        </div>

        {/* <div class="mt-8 sm:w-1/2 sm:mt-0 lg:w-1/5 lg:mt-0">
          <h6 class="text-lg font-bold uppercase">Products</h6>
          <ul class="mt-4">
            <li class="text-gray-500 hover:text-gray-300 mb-3"><a href="#">Charitable Trust</a></li>
            <li class="text-gray-500 hover:text-gray-300 mb-3"><a href="#">Commiunity Power</a></li>
          </ul>
        </div> */}
        {/* <div class="mt-8 mb-16 sm:w-1/2 sm:mt-12 lg:w-1/5 lg:mt-0">
          <h6 class="text-lg font-bold uppercase">Get in Touch</h6>
          <ul class="mt-4">
          <li class="text-gray-500 hover:text-gray-300 mb-3"><a href="#">FAQ</a></li>
          <li class="text-gray-500 hover:text-gray-300 mb-3"><a href="#">Privacy Policy</a></li>
          <li class="text-gray-500 hover:text-gray-300 mb-3"><a href="#">Terms & Conditions</a></li>
          </ul>
        </div> */}
      </div>

      {/* <div class="mt-16">
        <p class="text-xs text-gray-600 text-center">2022 © WarmHearted. All rights reserved.</p>
      </div> */}
    </footer>
    )
}